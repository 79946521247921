import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { log } from 'console';
import { $ } from 'protractor';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class ApplicationApi {
  addThirdPartyApplications(agentData: { firstname: any; lastname: any; semester: any; Document: any; college: any; branch: any; passing: any; stream: any; }) {
    throw new Error('Method not implemented.');
  }
  private readonly apiController: string = 'application';

  constructor(private api: HttpService,
    private http: HttpClient) { }

  addApplication(studentData: {
    firstname: any; lastname: any; semester: any; Document: any; reqType: any; college: any; branch: any; passing: any; userId: any; userEmail: any; created_by: any;
  }, emails: Set<any>) {
    return this.http.post(`${environment.apiUrl}/addApplication`, { "studentData": studentData, "emails": Array.from(emails) })
  }

  private handleError(error) {
    console.error(error);
  }

  updateApplication(id, data, emails: Set<any>) {
    return this.http.post(`${environment.apiUrl}/updateApplication`, { "id": id, "data": data, "emails": Array.from(emails) })
  }
  getuserdata(id) {
    return this.http.get(`${environment.apiUrl}/getuserdata?id=` + id)
  }

  getapplicantdata(id) {
    return this.http.get(`${environment.apiUrl}/getapplicantdata?id=` + id)
  }

  getEduLevelCourses(level) {
    return this.http.get(`${environment.apiUrl}/getEduLevelCouses?level=` + level)
  }

  // getColleges() {
  //   return this.http.get(`${environment.apiUrl}/getColleges?level=`)
  // }

  getColleges() {
    return this.http.get(`${environment.apiUrl}/Program_List`)
  }

  updateFirstFormData(item: any,) {
    return this.http.post(`${environment.apiUrl}/updateFirstFormData`, { 'item': item })

  }

  emailValues(data) {
    try {
      return this.http.post(`${environment.apiUrl}/checkEmail`, { data: data });
    } catch (error) {
    }
  }

  sendOtpStudent(user_id) {
    return this.http.get(`${environment.apiUrl}/get_otp_student?user_id=${user_id}`);
  }

  updateOtp(user_id) {
    return this.http.get(`${environment.apiUrl}/update_otp?user_id=` + user_id);
  }

  resendOtp(user_id) {

    return this.http.get(`${environment.apiUrl}/get_otp_resend?user_id=${user_id}`);
  }


  updateOtpVerified(userId) {
    return this.http.post(`${environment.apiUrl}/update-otp-verified`, { 'id': userId })
  }

  updatesecondformData(item: any, id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/updatesecondformData`, { 'item': item, 'appli_id': id })
  }

  thirdformData(item: any, id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/thirdformData`, { 'item': item, 'appli_id': id })
  }

  getApplicantFiles(applicant_id): Observable<any> {

    return this.http.get(`${environment.apiUrl}/getApplicantFiles?applicant_id=` + applicant_id)
  }


  getApplicantFilesOne(applicant_id): Observable<any> {

    return this.http.get(`${environment.apiUrl}/getApplicantFilesOne?applicant_id=` + applicant_id)
  }


  onEntDeleteexamMarksheet(id) {
    
    return this.http.delete(`${environment.apiUrl}/onEntDeleteexamMarksheet?id=` + id['id'] + '&userId=' + id['userId'] + '&file_name=' + id['file_name'] + '&file_path=' + id['file_path'])


  }

  saveNotes(id, notes) {
    try {
      return this.http.post(`${environment.apiUrl}/saveNotes`, { id: id, notes: notes });
    } catch (error) {
      this.handleError("saveNotes : " + JSON.stringify(error));
    }
  }

  updateYearData(item: any, id): Observable<any> {
    return this.http.post(`${environment.apiUrl}/updateYearData`, { 'item': item, 'appli_id': id })
  }

  downloadExcel(type) {
    try {
      return this.http.get(`${environment.apiUrl}/downloadExcel?type=` + type)
    } catch (error) {
      this.handleError("downloadExcel :" + JSON.stringify(error))
    }


  }


  downloadExcelFiles(file_path): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadExcelFiles?file_path=${file_path}`, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      this.handleError("unable to get Files : " + JSON.stringify(error));
    }

  }


  deleteApplicantFile(id): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/deleteTranscripts?id=` + id['id'] + '&userId=' + id['userId'] + '&file_name=' + id['file_name'] + '&file_path=' + id['file_path'])
  }

  setEducationalValues(item: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}/set_Educational_Values`, { 'item': item })
  }

  getPredictivecourse(year, semester, courseName, enrollYear, collegeName) {
    var encodedCourse = encodeURIComponent(courseName);
    var college = encodeURIComponent(collegeName);
    return this.http.get(`${environment.apiUrl}/getPredictivecourse?year=` + year + '&semester=' + semester + '&courseName=' + encodedCourse + '&enrollYear=' + enrollYear + '&collegeName=' + college);
  }

  getCourses(type) {
    return this.http.get(`${environment.apiUrl}/getCoursese?type=` + type)
  }

  getEducation(edu_id, user_id) {
    return this.http.get(`${environment.apiUrl}/getEducation?user_id=` + user_id + `&edu_id=` + edu_id)
  }

  deleteEducation(id) {
    try {
      return this.http.post(`${environment.apiUrl}/deleteEducationdata`, { 'id': id });
    } catch (error) {
      this.handleError('deleteEducationdata: ' + JSON.stringify(error));
    }
  }

  downloadTranscript(fileName, id) {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadtranscripts?fileName=${fileName}` + '&id=' + id, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      this.handleError("unable to get Files : " + JSON.stringify(error));
    }
  }

  checkTabs(applicant_id, editFlag) {
    return this.http.get(`${environment.apiUrl}/checkTabs?applicant_id=` + applicant_id + '&editFlag=' + editFlag);
  }

  getApplicantAtkt(applicant_id): Observable<any> {
    return this.http.get(`${environment.apiUrl}/getApplicantAtkt?applicant_id=` + applicant_id)
  }

  deleteApplicantAtkt(data): Observable<any> {
    return this.http.delete(`${environment.apiUrl}/deleteTranscripts?id=` + data['id'] + '&userId=' + data['userId'] + '&file_name=' + data['file_name'] + '&file_path=' + data['file_path'])
  }

  preview(app_id) {
    return this.http.get(`${environment.apiUrl}/preview?app_id=` + app_id)
  }


  getApplicantDataPay(applicant_id) {
    return this.http.get(`${environment.apiUrl}/getApplicantData_pay?applicant_id=` + applicant_id)
  }


  signpdf(appl_id: any, type: any, dataValues: any) {
    return this.http.post(`${environment.apiUrl}/signpdf`, { "appl_id": appl_id, "type": type, "dataValues": dataValues })

  }

  checkTransStatus(user_id) {
    return this.http.get(`${environment.apiUrl}/getAppTransStatus?user_id=${user_id}`)
  }
  getAllSubadmin(email, name, roles, page) {
    try {
      return this.http.get(`${environment.apiUrl}/getAllSubadmin?email=` + email + '&name=' + name + '&roles=' + roles + '&page=' + page);
    } catch (error) {
      console.error(error);
    }
  }

  getCourseData() {
    return this.http.get(`${environment.apiUrl}/getCourseData`);
  }

  addUpdatesubAdmin(userId, subAdminData) {
    try {
      return this.http.post(`${environment.apiUrl}/addUpdatesubAdmin`, { "userId": userId, "subAdminData": subAdminData });
    } catch (error) {
      this.handleError("addUpdatesubAdmin: " + JSON.stringify(error));
    }
  }

  getRolesData(userId) {
    try {
      return this.http.get(`${environment.apiUrl}/getRolesData?userId=` + userId);
    } catch (error) {
      console.error("roles Data", error);
    }
  }

  getMenuRole(userId) {
    try {
      return this.http.get(`${environment.apiUrl}/getMenuRole?userId=` + userId);
    } catch (error) {
      this.handleError("getmenu" + JSON.stringify(error));
    }
  }
  changeSubAdminStatus(userId) {
    try {
      return this.http.post(`${environment.apiUrl}/changeSubAdminStatus`, { "userId": userId });
    } catch (error) {
      this.handleError("ChangeSubAdminStatus: " + JSON.stringify(error));
    }
  }

  addUpdateRole(roles, userId) {
    try {
      return this.http.post(`${environment.apiUrl}/addUpdateRoles`, { "roles": roles, "userId": userId });
    } catch (error) {
      this.handleError("ChangeSubAdminStatus: " + JSON.stringify(error));
    }
  }

  getSubAdminData(userId) {
    return this.http.get(`${environment.apiUrl}/getSubAdminData?userId=` + userId)
  }

  getPieCharts() {
    return this.http.get(`${environment.apiUrl}/getPieCharts`)
  }
  getallstudentfeedback(name, page) {
    return this.http.get(`${environment.apiUrl}/student_feeback?name=` + name + '&page=' + page);
  }
  activitytracker(email, page) {

    return this.http.get(`${environment.apiUrl}/activitytracker?email=` + email + '&page=' + page);
  }

  coursewisestats(year) {
    return this.http.get(`${environment.apiUrl}/coursewisestats?year=` + year);
  }

  downloadExcelReasonWise(reason) {
    try {
      return this.http.get(`${environment.apiUrl}/downloadExcelReasonWise?reason=` + reason)
    } catch (error) {
      this.handleError("downloadExcel :" + JSON.stringify(error))
    }
  }

  getApplicationReasonWise(reason) {
    return this.http.get(`${environment.apiUrl}/getApplicationReasonWise?reason=` + reason)
  }


  addProfile(addProfile) {
    try {
      return this.http.post(`${environment.apiUrl}/updateProfiles`, { addProfile: addProfile });
    } catch (err) {
      console.error(err);

    }
  }

  getPendingApplications(appId, name, email, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/unsigned?appId=` + appId + '&name=' + name + '&email=' + email + '&course=' + encodedCourse + '&page=' + page);
  }


  getRequestedApplications(appId, name, email, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/requestedApplication?appId=` + appId + '&name=' + name + '&email=' + email + '&course=' + encodedCourse + '&page=' + page);
  }


  getChangedApplications(appId, name, email, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/changedApplication?appId=` + appId + '&name=' + name + '&email=' + email + '&course=' + encodedCourse + '&page=' + page);

  }

  getRejectedApplication(appId, name, email, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/getRejectedApplication?appId=` + appId + '&name=' + name + '&email=' + email + '&course=' + encodedCourse + '&page=' + page);
    //return this.httpClient.get(requestUrl);
  }

  My_pplication() {
    return this.http.get(`${environment.apiUrl}/my_applications`);
  }

  download_Manuals(file_name): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadMannual?file_name=` + file_name, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      this.handleError("unable to get Files : " + JSON.stringify(error));
    }
  }

  getAllPayments(userId) {
    return this.http.get(`${environment.apiUrl}/getAllPayments?userId=` + userId);
  }

  lockUnlockEdu(app_id, lock) {
    return this.http.post(`${environment.apiUrl}/lockUnlockEdu`, { "app_id": app_id, "lock": lock });

  }

  view_Application(id) {
    return this.http.get(`${environment.apiUrl}/my_applicationsProfile?id=` + id);
  }


  name_changes(FirstName, Fathername, LastName, mothername, coursename, enrollmentYear, enrollmentYearStandard, leavingYear, leavingYearStandard, userID) {
    return this.http.post(`${environment.apiUrl}/nameChange`, {
      data: userID,
      firstname: FirstName,
      fathername: Fathername,
      lastname: LastName,
      mothername: mothername,
      coursename: coursename,
      enrollmentYear: enrollmentYear,
      enrollmentYearStandard: enrollmentYearStandard,
      leavingYear: leavingYear,
      leavingYearStandard: leavingYearStandard
    });

  }

  async getAllTranscriptData(userId, app_id) {

    return await this.http.get(`${environment.apiUrl}/transcript_lock?userId=` + userId + '&app_id=' + app_id).toPromise();
  }


  async getAllEducationalData(userId) {
    return await this.http.get(`${environment.apiUrl}/getAllEducationalData?userId=` + userId).toPromise();
  }

  sendmessage(msg_from_admin_data) {
    return this.http.post(`${environment.apiUrl}/trans_sendmessage`, { "msg": msg_from_admin_data });

  }

  getFileStatus(msg_from_admin_data) {
    return this.http.get(`${environment.apiUrl}/getFileStatus`);

  }


  Change_CourseSystem(userId, name) {
    try {
      return this.http.post(`${environment.apiUrl}/Course_System_Change`, { "user_id": userId, "data": name })
    } catch (error) {
      this.handleError("Error in Course_System_Change:" + error)
    }
  }


  admin_pass_reset(email) {
    try {
      return this.http.post(`${environment.apiUrl}/admin_pass_reset`, { email })
    } catch (error) {
      this.handleError("adminResetPassword: " + JSON.stringify(error));

    }
  }

  forgotPass(email) {
    return this.http.post(`${environment.apiUrl}/forgot-password`, { email: email })
  }

  rejectApplication(user_id, app_id) {
    return this.http.post(`${environment.apiUrl}/rejectApplication`, { "user_id": user_id, "app_id": app_id });
  }
  
  admin_document_reset(userId, appl_id){
    
    try {
      
      return this.http.delete(`${environment.apiUrl}/adminResetDoc?userId=${userId}&appl_id=${appl_id}`);
    } catch (error) {
      this.handleError("adminResetDoc: "+JSON.stringify(error));
    }
  }
  getSignedApplications(name, appId, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/signedApplications?name=` + name + '&appId=' + appId + '&course=' + encodedCourse + '&page=' + page);
  }

  getPdfDetails(appl_id, user_id, action) {
    return this.http.get(`${environment.apiUrl}/getpdfDetails?user_id=${user_id}&appl_id=${appl_id}&action=${action}`);
  }

  downloadFilesAdmin_signedpdf(file_name): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadSignedpdf?file_name=${file_name}`, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      this.handleError("unable to get Files : " + JSON.stringify(error));
    }
  }

  checkSignedPdf(app_id, user_id) {
    try {
      return this.http.get(`${environment.apiUrl}/checkSignedPdf?app_id=${app_id}&user_id=${user_id}`);
    } catch (err) {
      console.error(err);

    }
  }

  sendMail(app_id) {
    try {
      return this.http.post(`${environment.apiUrl}/email_signpdf`, { "app_id": app_id });
    } catch (err) {
      console.error(err);

    }
  }

  pickUpDate(app_id, user_id, date) {
    try {
      return this.http.get(`${environment.apiUrl}/pickUpDate?app_id=${app_id}&user_id=${user_id}&date=${date}`);
    } catch (err) {
      console.error(err);

    }
  }
  regeneratePdf(appl_id, user_id) {

    return this.http.get(`${environment.apiUrl}/regeneratePdf?user_id=${user_id}&appl_id=${appl_id}`);

  }

  sendPickupMail(email, name, todate, fromdate) {
    try {
      return this.http.post(`${environment.apiUrl}/sendPickupMail`, { "email": email, "name": name, "todate": todate, "fromdate": fromdate });
    } catch (err) {
      console.error(err);

    }
  }

  getApplication(id) {
    return this.http.get(`${environment.apiUrl}/getApplication?id=` + id)
  }

  getApplicationIdWise(id) {
    return this.http.get(`${environment.apiUrl}/getApplicationIdWise?id=` + id)
  }
  getApplications(applid, name, email, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/getApplications?applid=` + applid + '&name=' + name + '&email=' + email + '&course=' + encodedCourse + '&page=' + page)
  }
  getProcessedApplications(appId, name, course, page) {
    var encodedCourse = encodeURIComponent(course);
    return this.http.get(`${environment.apiUrl}/getProcessedApplications?appId=` + appId + '&name=' + name + '&course=' + encodedCourse + '&page=' + page)
  }
  getErrataApplications() {
    return this.http.get(`${environment.apiUrl}/getErrataApplications`)
  }
  getAgents() {
    return this.http.get(`${environment.apiUrl}/getAgents`)
  }

  checkApplication(email) {
    return this.http.get(`${environment.apiUrl}/checkApplication?email=${email}`);
  }

  paymentrequest(app_id, app_name, app_email, amount) {
    return this.http.post(`${environment.apiUrl}/paymentrequest`, { "app_id": app_id, "app_email": app_email, "amount": amount, "app_name": app_name })
  }

  PaymentDetails(order_id) {
    return this.http.get(`${environment.apiUrl}/PaymentDetails?order_id=${order_id}`)
  }
  OnlinePaymentChallan(transaction_id, payment_amount, payment_status, application_id, payment_date_time, user_id, order_id) {
    try {
      return this.http.post(`${environment.apiUrl}/OnlinePaymentChallan`, { "user_id": user_id, "payment_amount": payment_amount, "transaction_id": transaction_id, "date_time": payment_date_time, "status_payment": payment_status, "application_id": application_id, "order_id": order_id });
    } catch (error) {
      // this.handleError("OnlinePaymentChallan : "+error);
    }
  }

  downloadFiles(file_name, user_id): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/download?file_name=` + file_name + '&user_id=' + user_id, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (error) {
      this.handleError("unable to get Files : " + JSON.stringify(error));
    }
  }
  processApplication(id) {
    return this.http.post(`${environment.apiUrl}/processApplication`, { "id": id })
  }

  getPaymentDetails(tab_type, dataSearch, page) {
    try {
      return this.http.get(`${environment.apiUrl}/getPaymentDetails?tab_type=` + tab_type + '&dataSearch=' + dataSearch + '&page=' + page);
    } catch (err) {
      console.error(err);
    }
  }

  splitExcel() {
    try {
      return this.http.get(`${environment.apiUrl}/splitExcel`);
    } catch (err) {
      console.error(err);
    }
  }

  feedBack(satisfy, recommend, staff, experience, exp_prob, suggestion) {
    try {
      return this.http.post(`${environment.apiUrl}/feedBack`, { "satisfy": satisfy, "recommend": recommend, "staff": staff, "experience": experience, "exp_prob": exp_prob, "suggestion": suggestion });
    } catch (error) {
      this.handleError("feedBack : " + error);
    }
  }

  downloadPaymentExcel(pdf): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadExcel?pdf=` + pdf, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (err) {
      this.handleError("downloadPaymentExcel unable to get Files : " + JSON.stringify(err));
    }
  }

  downloadpayout(payid) {
    try {
      return this.http.get(`${environment.apiUrl}/downloadpayout?payid=${payid}`);
    } catch (err) {
      this.handleError("downloadPaymentExcel unable to get Files : " + JSON.stringify(err));

    }
  }

  downloadFilesExcel(file_path): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadfile?file_path=` + file_path, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' });
        });
    } catch (err) {
      this.handleError("downloadPaymentExcel unable to get Files : " + JSON.stringify(err));

    }
  }

  getPayoutdetails(date) {
    try {
      return this.http.get(`${environment.apiUrl}/getPayoutdetails?date=` + date);
    } catch (err) {
      console.error(err);

    }
  }

  proceedSplit(order_id, edu_share, uni_share, ccavenue_share, reference_no) {
    try {
      return this.http.post(`${environment.apiUrl}/proceedSplit`, { "order_id": order_id, "edu_share": edu_share, "uni_share": uni_share, "ccavenue_share": ccavenue_share, "reference_no": reference_no });
    } catch (err) {
      console.error(err);

    }
  }

  proceedRefund(order_no, order_id, edu_refund, uni_refund, cc_refund, reference_no) {
    try {
      return this.http.post(`${environment}/proceedRefund`, { "order_no": order_no, "order_id": order_id, "edu_refund": edu_refund, "uni_refund": uni_refund, "cc_refund": cc_refund, "reference_no": reference_no })
    } catch (err) {
      console.error(err);

    }
  }

  errataApplication(id, userId, errataMesg, first_name, last_name, choose_file, lastErrata) {
    return this.http.post(`${environment.apiUrl}/errataApplication`, {
      "id": id, "userId": userId, "errataMesg": errataMesg, "name": first_name,
      "surname": last_name, "choose_file": choose_file, "lastErrata": lastErrata
    })
  }
  viewDocument(url) {
    return this.http.get(url)
  }

  downloadReceiptFiles(file_name, userId): Observable<Blob> {
    try {
      let headers = new HttpHeaders();
      headers.append('Content-Type', 'application/json');
      return this.http.get(`${environment.apiUrl}/downloadpayreceipt?file_name=${file_name}&userId=${userId}`, { headers: headers, responseType: 'blob' }).map(
        (res) => {
          return new Blob([res], { type: 'application/pdf' })
        });
    } catch (error) {
      this.handleError("unable to get Files : " + JSON.stringify(error));

    }

  }

  getInputFieldsData(applicantId,format) {
    try {
      return this.http.get(`${environment.apiUrl}/getInputFieldsData?applicantId=${applicantId}&format=${format}`);
    } catch (error) {
      this.handleError("getInputFieldsData : " + JSON.stringify(error));
    }
  }

  getCheckForInputFieldsData(applicantId,format) {
    try {
      return this.http.get(`${environment.apiUrl}/getCheckForInputFieldsData?applicantId=${applicantId}&format=${format}`);
    } catch (error) {
      this.handleError("getCheckForInputFieldsData : " + JSON.stringify(error));
    }
  }
}