<div class="row mcr">
  <div class="col-md-6 form">

    <div class="logo1">
      <img src=".\assets\images\sulogo.png" style="width:10px; " alt="logo">
    </div>

    <h1 style="text-align: center; color: DodgerBlue;">Register</h1>

    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
      <p class="alert-title"><b>Oh snap!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
      </ul>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">
      <p class="alert-title"><b>Hooray!</b></p>
      <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
      </ul>
    </nb-alert>

    <div [formGroup]="registerForm" aria-labelledby="title">

      <div class="form-control-group">
        <label for="input-name">Applicants First Name Only:</label><span class="text-danger pl-1">*</span>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-name" formControlName="firstName"
          [status]="firstName.dirty ? (firstName.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="firstName.invalid && firstName.touched ? true : null" placeholder="First Name">
        <ngx-validation-message label="First Name"
          [showMinLength]="firstName?.hasError('minlength') && firstName.touched"
          [showMaxLength]="firstName?.hasError('maxlength') && firstName.touched" [minLength]="minfirstNameLength"
          [maxLength]="maxfirstNameLength" [showRequired]="firstName?.errors?.required && firstName.touched">
        </ngx-validation-message>
        <div *ngIf="firstName.hasError('pattern')" style="color:red">Please enter only alphabetical characters</div>
      </div>



      <div class="form-control-group">
        <label for="input-name">Last Name/ Surname Only:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-lastName" formControlName="lastName"
          [status]="lastName.dirty ? (lastName.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="lastName.invalid && lastName.touched ? true : null" placeholder="Last Name">
        <ngx-validation-message label="Last Name" [showMinLength]="lastName?.hasError('minlength') && lastName.touched"
          [showMaxLength]="lastName?.hasError('maxlength') && lastName.touched" [minLength]="minlastNameLength"
          [maxLength]="maxlastNameLength" [showRequired]="lastName?.errors?.required && lastName.touched">
        </ngx-validation-message>
        <div *ngIf="lastName.hasError('pattern')" style="color:red">Please enter only alphabetical characters</div>

      </div>




      <div class="form-control-group">
        <label for="input-name">Father's First Name Only:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-fatherName" formControlName="fatherName"
          [status]="fatherName.dirty ? (fatherName.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="fatherName.invalid && fatherName.touched ? true : null" placeholder="Father Name">
        <ngx-validation-message label="Father Name"
          [showMinLength]="fatherName?.hasError('minlength') && fatherName.touched"
          [showMaxLength]="fatherName?.hasError('maxlength') && fatherName.touched" [minLength]="minFatherNameLength"
          [maxLength]="maxFatherNameLength" [showRequired]="fatherName?.errors?.required && fatherName.touched">
        </ngx-validation-message>
        <div *ngIf="fatherName.hasError('pattern')" style="color:red">Please enter only alphabetical characters</div>

      </div>

      <div class="form-control-group">
        <label for="input-name">Mother's First Name Only:</label>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-motherName" formControlName="motherName"
          [status]="motherName.dirty ? (motherName.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="motherName.invalid && motherName.touched ? true : null" placeholder="Mother Name">
        <ngx-validation-message label="Mother Name"
          [showMinLength]="motherName?.hasError('minlength') && motherName.touched"
          [showMaxLength]="motherName?.hasError('maxlength') && motherName.touched" [minLength]="minMotherNameLength"
          [maxLength]="maxMotherNameLength" [showRequired]="motherName?.errors?.required && motherName.touched">
        </ngx-validation-message>
        <div *ngIf="motherName.hasError('pattern')" style="color:red">Please enter only alphabetical characters</div>

      </div>

      <div class="form-control-group">
        <label for="input-name">Mobile Number:</label><span class="text-danger pl-1">*</span>
        <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
          [enableAutoCountrySelect]="true" [enablePlaceholder]="false" [searchCountryFlag]="true"
          [searchCountryField]="['iso2','name']" [selectFirstCountry]="false" [selectedCountryISO]="'in'"
          [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode"
          [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone"
          (ngModelChange)="phonefuc(phone)"></ngx-intl-tel-input>
        <p class="caption status-danger" *ngIf="!phone.valid">
          kindly enter valid mobile number!
        </p>
      </div>

      <!-- <form #f="ngForm" [formGroup]="phoneForm"> -->
      <div class="form-control-group">

        <!-- </form> -->
      </div>

      <div class="form-control-group">
        <label for="input-email">Email address:</label><span class="text-danger pl-1">*</span>
        <input (keyup)="onKeyPressNew($event)" nbInput fullWidth fieldSize="large" id="input-email"
          formControlName="email" [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : ''"
          [attr.aria-invalid]="email.invalid && email.touched ? true : null" placeholder="Email address">
        <ngx-validation-message label="Email address" [showPattern]="email?.hasError('pattern') && email.touched"
          [showRequired]="email?.errors?.required && email.touched"></ngx-validation-message>
      </div>

      <div class="form-control-group">
        <label for="input-password">Password:</label><span class="text-danger pl-1">*</span>
        <input nbInput fullWidth fieldSize="large" id="input-password" formControlName="password" type="password"
          [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : ''" placeholder="Password">
        <ngx-validation-message label="Password" [showMinLength]="password?.hasError('minlength') && password.touched"
          [showMaxLength]="password?.hasError('maxlength') && password.touched"
          [showRequired]="password?.errors?.required && password.touched" [minLength]="minLength"
          [maxLength]="maxLength"></ngx-validation-message>
      </div>

      <div class="form-control-group">
        <label for="input-re-password">Confirm Password:</label><span class="text-danger pl-1">*</span>
        <input nbInput fullWidth fieldSize="large" id="input-re-password" formControlName="confirmPassword"
          type="password"
          [status]="confirmPassword.dirty ? (confirmPassword.invalid || password.value != confirmPassword.value  ? 'danger' : 'success') : ''"
          placeholder="Password">
        <ngx-validation-message label="Confirm Password"
          [showMinLength]="confirmPassword?.hasError('minlength') && confirmPassword.touched"
          [showMaxLength]="confirmPassword?.hasError('maxlength') && confirmPassword.touched"
          [showRequired]="confirmPassword?.errors?.required && confirmPassword.touched" [minLength]="minLength"
          [maxLength]="maxLength"></ngx-validation-message>
        <p class="caption status-danger" *ngIf="password.value != confirmPassword.value">
          Password and confirm password does not match!
        </p>
      </div>
      <div class="form-control-group">
        <label for="input-re-password">Date Of Birth </label><span class="text-danger pl-1">*</span>
        <input nbInput placeholder="Pick Date" formay class="form-control" [nbDatepicker]="dateTimePicker"
          formControlName="dob">
        <nb-datepicker #dateTimePicker></nb-datepicker>
      </div>

      <div class="form-control-group">
        <label for="input-re-password">Gender</label><span class="text-danger pl-1">*</span>

        <nb-radio-group formControlName="gender" style="display: flex;">
          <nb-radio value="Male">Male</nb-radio>
          <nb-radio value="Female">Female</nb-radio>
          <nb-radio value="Others">Other</nb-radio>
        </nb-radio-group>
      </div>

      <div class="form-control-group">
        <label for="input-name">Aadhar Number:</label><span class="text-danger pl-1">*</span>
        <input nbInput autofocus fullWidth fieldSize="large" id="input-name" formControlName="aadharNo"
               placeholder="Aadhar Number">
        <ngx-validation-message label="Aadhar Number"></ngx-validation-message>
        <div *ngIf="registerForm.controls.aadharNo.errors?.pattern && (registerForm.controls.aadharNo.dirty || registerForm.controls.aadharNo.touched)" style="color:red">Please enter only numeric value</div>
        <div *ngIf="!registerForm.controls.aadharNo.errors?.pattern && registerForm.controls.aadharNo.errors?.required && (registerForm.controls.aadharNo.dirty || registerForm.controls.aadharNo.touched)" style="color:red">Aadhar Number is required</div>
        <div *ngIf="!registerForm.controls.aadharNo.errors?.pattern && registerForm.controls.aadharNo.errors?.minlength && (registerForm.controls.aadharNo.dirty || registerForm.controls.aadharNo.touched)" style="color:red">Aadhar Number must be 12 digits long</div>
        <div *ngIf="!registerForm.controls.aadharNo.errors?.pattern && registerForm.controls.aadharNo.errors?.maxlength && (registerForm.controls.aadharNo.dirty || registerForm.controls.aadharNo.touched)" style="color:red">Aadhar Number must be 12 digits long</div>
      </div>
      
      


      <button nbButton fullWidth status="primary" size="large" [disabled]="submitted || !registerForm.valid 
            || password.value != confirmPassword.value" [class.btn-pulse]="submitted" (click)="register()">
        Register
      </button>
    </div>

    <h6 class="login1" aria-label="Sign in">
      Already have an account? <a class="text-link" style="color:DodgerBlue;" routerLink="../login">Log in</a>
    </h6>
  </div>

  <div class="col-md-6 about">
    <div class="elip"></div>
    <div style="position: absolute;z-index: 2;left: 0; height: 100%; width: 100%;">
      <div style="display: flex; flex-direction: column; align-items: center;justify-content: center; height: 100%;">
        <div class="logo">
          <img src=".\assets\images\sulogo.png" alt="logo">
        </div>

        <div class="imge">
          <img src=".\assets\images\Recruitment Agencies ( verification).svg">
        </div>
        <h4> Already have an account? <a style="color:DodgerBlue;" class="text-link" routerLink="../login">Login</a>
        </h4>
      </div>
    </div>
  </div>
</div>
<p-dialog [(visible)]="emailverify" [modal]="true" [responsive]="true" [style]="{width: '425px'}">
  <h3>Kindly Change Your Email Address</h3>
</p-dialog>
<p-confirmDialog header="Confirmation" [style]="{width: '425px'}" icon="pi pi-exclamation-triangle"></p-confirmDialog>