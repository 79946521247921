import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbDialogRef } from '@nebular/theme';
import { RegisterUserApi } from '../../../@core/backend/common/api/registerUser.api';
import { InitUserService } from '../../../@theme/services/init-user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NetworkService } from '../../../@core/utils';

@Component({
  selector: 'ngx-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.scss']
})
export class OtpComponent implements OnInit {
  @Input() user_id;
  @Input() landingPage;
  otp;
  enterOtp;
  private destroy$: Subject<void> = new Subject<void>();
  otpValidation: boolean;

  constructor(
    protected ref: NbDialogRef<OtpComponent>,
    protected initUserService: InitUserService,
    private api: RegisterUserApi,
    protected router: Router,
    protected network: NetworkService
  ) { }

  ngOnInit(): void {
    
    
    this.network.getMachinId(this.user_id);
    // this.api.sendOtp(this.user_id).subscribe(data=>{
    //  // if(data['status'] == 200){
    //     this.otp = data['data']
    //   //}
    // })
  }

  cancel() {
    this.ref.close();
    this.router.navigate(['auth/logout']);
  }


  resendOtp() {
    this.api.sendOtp(this.user_id).subscribe(data => {
      //if(data['status'] == 200){
      this.otp = data['data']
      //}
    })
  }
  verify() {
    this.enterOtp = (document.getElementById('enterOtp') as HTMLInputElement).value;
    if (this.otp === this.enterOtp) {
      this.api.updateOtp(this.user_id).subscribe(data => {
        if (data['status'] == 200) {
          // return this.router.navigateByUrl(this.landingPage);
          if (data['data'].is_otp_verified == 1 || data['data'].is_email_verified == 1) {
            this.initUser(data['data'].id, data['data'].user_type, data['data'].email, data['data'].firstname,
              data['data'].lastname, data['data'].mobile);
            this.router.navigateByUrl(this.landingPage);
          }
        }
      })
    } else {
      this.otpValidation = false;

    }
  }

  initUser(id, role, email, firstname, lastname, mobile) {
    this.initUserService.initCurrentUser(id, role, email, firstname, lastname, mobile)
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe();
  }

}
