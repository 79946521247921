<nb-card style="width: 100%;">
  <nb-card-header id="header" style="text-align: center;">Verify Otp Here </nb-card-header>
  <nb-card-body>
    <div class="row" style="margin: 0 auto;">
      <input pattern="[0-9]+" id="enterOtp" style="visibility: visible;" nbInput type="number" (keyup.enter)="verify()"
        placeholder="Enter OTP Here" class="form-control" autocomplete="shipping postal-code" required>
      <span *ngIf="otpValidation" style="color:red;">Please enter OTP</span>

    </div>
  </nb-card-body>
  <nb-card-footer>
    <div style="display: flex; justify-content: space-around;">
      <div><button nbButton hero status="primary" id="verify" style="visibility: visible;"
          (click)="verify()">Verify</button></div>
      <div><button nbButton hero status="primary" id="cancel" style="visibility: visible;"
          (click)="cancel()">Cancel</button></div>
      <div><button nbButton hero status="primary" id="verify" style="visibility: visible;" (click)="resendOtp()">Resend
          Otp</button></div>
    </div>
  </nb-card-footer>
</nb-card>