<div>
    <div class="container-fluid">
        <h4 *ngIf="!errataFlag">New Application</h4>
        <h4 *ngIf="errataFlag">Edit Application</h4>

        <nb-card class="container-fluid">
            <nb-card-header>Fill the form below to verify your document.</nb-card-header>


            <nb-card-body>
                <form [formGroup]="verifyGroup" #formDirective="ngForm">
                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="firstNameCtrl" class="form-control-label">First Name &nbsp; &nbsp; <i
                                class="fa-solid fa-id-card"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <input formControlName="firstNameCtrl" name="firstNameCtrl" id="firstNameCtrl"
                                class="form-control" type="text" placeholder="First Name">
                        </div>

                        <div class="form-group col-md-4">
                            <label for="lastNameCtrl" class="form-control-label">Last
                                Name &nbsp; &nbsp; <i class="fa-solid fa-id-card"></i></label><span class="text-danger pl-1">*</span>
                            <input formControlName="lastNameCtrl" name="lastNameCtrl" id="lastNameCtrl"
                                class="form-control" type="text" placeholder="Last Name">
                        </div>

                        <div class="form-group col-md-4">
                            <label for="seatCtrl" class="form-control-label">Seat &nbsp; &nbsp;  <i class="fa-solid fa-chair"></i>
                                Number
                            </label><span class="text-danger pl-1">*</span>
                            <input formControlName="seatCtrl" name="seatCtrl" id="seatCtrl" class="form-control"
                                type="number" placeholder="Seat Number">
                        </div>

                    </div>


                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="streamCtrl" class="form-control-label">Stream &nbsp; &nbsp; <i
                                class="fa-solid fa-bridge-water"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <select name="streamCtrl" id="streamCtrl" class="form-control" placeholder="Stream"
                                formControlName="streamCtrl">
                                <option _ngcontent-jco-c75="" value="" disabled="" selected=""> Please select Stream
                                </option>
                                <option _ngcontent-jco-c75="" value="ETRXs" class="ng-star-inserted"> ETRXs</option>
                                <option _ngcontent-jco-c75="" value=" BE" class="ng-star-inserted"> BE</option>
                                <option _ngcontent-jco-c75="" value=" Master of Business Administration"
                                    class="ng-star-inserted"> Master of Business Administration</option>
                                <option _ngcontent-jco-c75="" value="BSc" class="ng-star-inserted"> BSc</option>
                                <option _ngcontent-jco-c75="" value="B.Com" class="ng-star-inserted"> B.Com</option>
                                <option _ngcontent-jco-c75="" value="Bachelor of Education" class="ng-star-inserted">
                                    Bachelor of Education</option>
                                <option _ngcontent-jco-c75="" value=" Master of Science" class="ng-star-inserted">
                                    Master of Science</option>
                                <option _ngcontent-jco-c75="" value="NA" class="ng-star-inserted"> NA</option>
                                <option _ngcontent-jco-c75="" value="Bachelor of Physical Education"
                                    class="ng-star-inserted"> Bachelor of Physical Education</option>
                                <option _ngcontent-jco-c75="" value="Master of Physical Education"
                                    class="ng-star-inserted"> Master of Physical Education</option>
                                <option _ngcontent-jco-c75="" value="Diploma in Civil Engineering"
                                    class="ng-star-inserted"> Diploma in Civil Engineering</option>
                                <option _ngcontent-jco-c75="" value=" Bachelor of Arts" class="ng-star-inserted">
                                    Bachelor of Arts</option>
                                <option _ngcontent-jco-c75="" value=" Master of Arts" class="ng-star-inserted"> Master
                                    of Arts</option>
                                <option _ngcontent-jco-c75="" value=" Bachelor of Law" class="ng-star-inserted">
                                    Bachelor of Law</option>
                                <option _ngcontent-jco-c75="" value="Bachelor of Managment Studies"
                                    class="ng-star-inserted"> Bachelor of Managment Studies</option>
                                <option _ngcontent-jco-c75="" value="Master of Commerce" class="ng-star-inserted">
                                    Master of Commerce</option>
                                <option _ngcontent-jco-c75="" value="Master of Education" class="ng-star-inserted">
                                    Master of Education</option>
                                <option _ngcontent-jco-c75="" value=" Bachelor of Pharmacy" class="ng-star-inserted">
                                    Bachelor of Pharmacy</option>
                                <option _ngcontent-jco-c75="" value=" DOCTOR OF PHILOSOPHY" class="ng-star-inserted">
                                    DOCTOR OF PHILOSOPHY</option>
                                <option _ngcontent-jco-c75="" value=" Bachelor Of Mass Media" class="ng-star-inserted">
                                    Bachelor Of Mass Media</option>
                                <option _ngcontent-jco-c75="" value=" M.A." class="ng-star-inserted"> M.A.</option>
                                <option _ngcontent-jco-c75="" value=" MASTER OF MANAGEMENT STUDIES (M.M.S.)"
                                    class="ng-star-inserted"> MASTER OF MANAGEMENT STUDIES (M.M.S.)</option>
                                <option _ngcontent-jco-c75="" value="BACHELOR OF ARCHITECTURE DEGREE"
                                    class="ng-star-inserted"> BACHELOR OF ARCHITECTURE DEGREE</option>
                                <option _ngcontent-jco-c75="" value=" Master of Computer Application"
                                    class="ng-star-inserted"> Master of Computer Application</option>
                                <option _ngcontent-jco-c75="" value="Diploma in Medical Laboratory Technology"
                                    class="ng-star-inserted"> Diploma in Medical Laboratory Technology</option>
                                <option _ngcontent-jco-c75="" value=" Bachelor Of Final Arts / Performinig Arts (Dance)"
                                    class="ng-star-inserted"> Bachelor Of Final Arts / Performinig Arts (Dance)</option>
                                <option _ngcontent-jco-c75="" value="Master Of Marketing Management"
                                    class="ng-star-inserted"> Master Of Marketing Management</option>
                                <option _ngcontent-jco-c75="" value="Master OF Library And Information Science"
                                    class="ng-star-inserted"> Master OF Library And Information Science</option>
                                <option _ngcontent-jco-c75="" value=" MASTER'S DEGREE IN MARKETING MANAGEMENT (M.M.M.)"
                                    class="ng-star-inserted"> MASTER'S DEGREE IN MARKETING MANAGEMENT (M.M.M.)</option>
                                <option _ngcontent-jco-c75="" value="INTERMEDIATE SCIENCE EXAMINATION"
                                    class="ng-star-inserted"> INTERMEDIATE SCIENCE EXAMINATION</option>
                                <option _ngcontent-jco-c75="" value=" Diploma in Medical" class="ng-star-inserted">
                                    Diploma in Medical</option>
                                <option _ngcontent-jco-c75="" value=" Post Graduate Diploma (PGDFM)"
                                    class="ng-star-inserted"> Post Graduate Diploma (PGDFM)</option>
                                <option _ngcontent-jco-c75="" value=" Doctor of Medicine" class="ng-star-inserted">
                                    Doctor of Medicine</option>
                                <option _ngcontent-jco-c75="" value=" Master Engineering" class="ng-star-inserted">
                                    Master Engineering</option>
                                <option _ngcontent-jco-c75="" value=" Maseter Of Surgery" class="ng-star-inserted">
                                    Maseter Of Surgery</option>
                                <option _ngcontent-jco-c75="" value=" FOUNDATION II" class="ng-star-inserted">
                                    FOUNDATION II</option>
                                <option _ngcontent-jco-c75="" value=" Master of Performing Arts"
                                    class="ng-star-inserted"> Master of Performing Arts</option>
                                <option _ngcontent-jco-c75=""
                                    value="Bachelor Of Medicine and Bachelor Of Surgery (M.B.,B.S.)"
                                    class="ng-star-inserted"> Bachelor Of Medicine and Bachelor Of Surgery (M.B.,B.S.)
                                </option>
                                <option _ngcontent-jco-c75="" value=" MASTER'S IN HUMAN RESOURCES MANAGEMENT"
                                    class="ng-star-inserted"> MASTER'S IN HUMAN RESOURCES MANAGEMENT</option>
                                <option _ngcontent-jco-c75=""
                                    value=" BACHELOR OF HOTEL &amp; TOURISM MANAGEMENT STUDIES"
                                    class="ng-star-inserted"> BACHELOR OF HOTEL &amp; TOURISM MANAGEMENT STUDIES
                                </option>
                                <option _ngcontent-jco-c75="" value=" Master Of Pharmacy Degree"
                                    class="ng-star-inserted"> Master Of Pharmacy Degree</option>
                                <option _ngcontent-jco-c75="" value=" Master in Financial Management"
                                    class="ng-star-inserted"> Master in Financial Management</option>
                                <option _ngcontent-jco-c75="" value=" Master in Human Resources Development Management"
                                    class="ng-star-inserted"> Master in Human Resources Development Management</option>
                                <option _ngcontent-jco-c75="" value="Master in Information Management"
                                    class="ng-star-inserted"> Master in Information Management</option>
                                <option _ngcontent-jco-c75="" value=" Diploma In Radiological Physics"
                                    class="ng-star-inserted"> Diploma In Radiological Physics</option>
                                <option _ngcontent-jco-c75="" value=" DOCTER OF MEDICINE(AYURVEDA)"
                                    class="ng-star-inserted"> DOCTER OF MEDICINE(AYURVEDA)</option>
                                <option _ngcontent-jco-c75="" value="Bachelor Of Fine Arts" class="ng-star-inserted">
                                    Bachelor Of Fine Arts</option>
                                <option _ngcontent-jco-c75=""
                                    value=" Master of Financial Services Management (M.F.S.M) "
                                    class="ng-star-inserted"> Master of Financial Services Management (M.F.S.M)
                                </option>
                                <option _ngcontent-jco-c75="" value="DIPLOMA IN GYNAECOLOGY AND OBSTETRICS"
                                    class="ng-star-inserted"> DIPLOMA IN GYNAECOLOGY AND OBSTETRICS</option>
                                <option _ngcontent-jco-c75="" value="Diploma Course In Labour Laws and Labour Welfare"
                                    class="ng-star-inserted"> Diploma Course In Labour Laws and Labour Welfare</option>
                                <option _ngcontent-jco-c75="" value=" Master of Financial Management"
                                    class="ng-star-inserted"> Master of Financial Management</option>
                                <option _ngcontent-jco-c75="" value=" Master of Human Resource Development Management"
                                    class="ng-star-inserted"> Master of Human Resource Development Management</option>
                                <option _ngcontent-jco-c75="" value=" Bachelor of  Pharmaceutical Sciences "
                                    class="ng-star-inserted"> Bachelor of Pharmaceutical Sciences </option>
                                <option _ngcontent-jco-c75="" value=" B.A.M.S" class="ng-star-inserted"> B.A.M.S
                                </option>
                                <option _ngcontent-jco-c75="" value=" M.E." class="ng-star-inserted"> M.E.</option>
                                <option _ngcontent-jco-c75="" value=" Master of Engineering" class="ng-star-inserted">
                                    Master of Engineering</option>


                            </select>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="branchCtrl" class="form-control-label">Branch &nbsp; &nbsp; <i
                                class="fa-solid fa-code-branch"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <select name="branchCtrl" id="branchCtrl" class="form-control" placeholder="Branch"
                                formControlName="branchCtrl">
                                <option _ngcontent-jco-c75="" value="" disabled="" selected=""> Please select Branch
                                </option>
                                <option _ngcontent-jco-c75="" value="Finance" class="ng-star-inserted"> Finance</option>
                                <option _ngcontent-jco-c75="" value=" HR" class="ng-star-inserted"> HR</option>
                                <option _ngcontent-jco-c75="" value="Marketing" class="ng-star-inserted"> Marketing
                                </option>
                                <option _ngcontent-jco-c75="" value=" NA" class="ng-star-inserted"> NA</option>


                            </select>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="semCtrl" class="form-control-label">Semester &nbsp; &nbsp;<i class="fa-solid fa-list-ol"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <select name="semCtrl" id="semCtrl" class="form-control" placeholder="Description"
                                formControlName="semCtrl">
                                <option _ngcontent-jco-c75="" value="" disabled="" selected=""> Please select Semester
                                </option>
                                <option value="Sem I">Sem I</option>
                                <option value="Sem II">Sem II</option>
                                <option value="Sem III">Sem III</option>
                                <option value="Sem IV">Sem IV</option>
                                <option value="Sem V">Sem V</option>
                                <option value="Sem VI">Sem VI</option>
                                <option value="Sem VI">Sem VII</option>
                                <option value="Sem VI">Sem VIII</option>
                            </select>
                        </div>

                    </div>


                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="docCtrl" class="form-control-label">Document &nbsp; &nbsp; <i class="fa-solid fa-file"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <select name="docCtrl" id="docCtrl" class="form-control"
                                placeholder="Please select document" formControlName="docCtrl">
                                <option _ngcontent-jco-c75="" value="" disabled="" selected=""> Please select Document
                                </option>
                                <option value="Marksheet">Marksheet</option>



                            </select>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="reqCtrl" class="form-control-label">Request Type &nbsp; &nbsp; <i
                                class="fa-solid fa-certificate"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <select name="reqCtrl" id="reqCtrl" class="form-control"
                                placeholder="Please select Verification type" formControlName="reqCtrl">
                                <option _ngcontent-jco-c75="" value="" disabled="" selected=""> Please select Request
                                    Type
                                </option>
                                <option value="Verification">Verification</option>
                            </select>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="collegeCtrl" class="form-control-label">College Name &nbsp; &nbsp; <i
                                class="fa-solid fa-building-columns"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <select name="collegeCtrl" id="collegeCtrl" class="form-control"
                                placeholder="Please select college name" formControlName="collegeCtrl">
                                <option _ngcontent-jco-c75="" value="" disabled="" selected=""> Please select College
                                </option>
                                <option _ngcontent-jco-c75="" value="Mu-Affiliated" class="ng-star-inserted"> Mu
                                    Aflfiliated
                                </option>
                                <option _ngcontent-jco-c75="" value="Autonomous" class="ng-star-inserted"> Autonomous
                                </option>

                            </select>
                        </div>

                    </div>


                    <div class="row">
                        <div class="form-group col-md-4">
                            <label for="passCtrl" class="form-control-label">Year of Passing &nbsp; &nbsp;<i
                                class="fa-solid fa-calendar-days"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <input nbInput placeholder="Pick Date" class="form-control" [nbDatepicker]="dateTimePicker"
                                formControlName="passCtrl">
                            <nb-datepicker #dateTimePicker></nb-datepicker>
                            <br>

                            <div class="form-group">
                                <label for="mobile" class="form-control-label">Mobile number: &nbsp; &nbsp; <i class="fa-solid fa-mobile-screen"></i> </label>
                                <span class="text-danger pl-1">*</span>
                                <input type="number" formControlName="mobile" class="form-control mb-2" placeholder="Mobile Number">
                                <div *ngIf="m.mobile.touched && m.mobile.invalid" class="alert alert-danger">
                                    <div *ngIf="m.mobile.errors?.required">Please enter mobile number</div>
                                    <div *ngIf="m.mobile.errors?.pattern">Mobile number is not 10 digit</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-4">
                            <label for="emailCtrl" class="form-control-label">Alternate Email's(Max 5 ) &nbsp; &nbsp; <i
                                class="fa-solid fa-envelopes-bulk"></i>
                            </label><span class="text-danger pl-1">*</span>
                            <nb-form-field>
                                <input type="email" placeholder="Email" id="emailCtrl" formControlName="emailCtrl"
                                    autocomplete="email" [pattern]="emailPattern" nbInput email>
                                <button nbSuffix nbButton ghost (click)="onTagAdd()">
                                    <i class="fa-solid fa-plus"></i>
                                </button>
                            </nb-form-field>
                            <!-- <input type="email" 
                                placeholder="Email" 
                                id="emailCtrl" formControlName="emailCtrl"  
                                autocomplete="email">  -->
                            <!-- <input type="email" 
                                placeholder="Email" 
                                id="emailCtrl" formControlName="emailCtrl"  
                                autocomplete="email">  
                                <button nbButton status="primary" class="form-control"
                                (click)="onTagAdd()">Add Email</button>  -->
                            <nb-tag-list (tagRemove)="onTagRemove($event)">
                                <nb-tag *ngFor="let email of emails" [text]="email" removable></nb-tag>
                                <input type="text" nbTagInput fullWidth>
                            </nb-tag-list>

                        </div>
                        <div class="form-group col-md-4">
                            <div class="progress form-group" *ngIf="progress > 0">
                                <div class="progress-bar progress-bar-striped bg-success" role="progressbar"
                                    [style.width.%]="progress"></div>
                            </div>

                            <div class="form-group">
                                <label for="fileCtrl" class="form-control-label"><i
                                        class="fa-solid fa-upload"></i>Upload Document
                                </label><span class="text-danger pl-1">*</span>
                                <!-- <input type="file" formControlName="fileCtrl" (change)="uploadFile($event)" /> -->
                                <input type="file" formControlName="fileCtrl" (change)="uploadFile($event)" />
                                <div style="color: red;">All semester marksheets should be uploaded in one PDF (upto 5
                                    MB).</div>
                            </div>
                            <div *ngIf="chooseFlag" class="col-md-6" id="cret" style="text-align: right;">
                                <button nbButton status="primary"  (click)="openDocument()">View Document &nbsp;&nbsp;&nbsp;<i class="fa-solid fa-eye"></i></button>
                              </div>
                        </div>

                    </div>

                   

                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-3">
                                <button nbButton status="danger" class="form-control"
                                    (click)="reset(formDirective)">Reset &nbsp;&nbsp;&nbsp;<i
                                        class="fa-solid fa-arrows-rotate"></i></button>
                            </div>
                            <div *ngIf="!errataFlag" class="col-md-3">
                                <button nbButton status="primary" class="form-control" (click)="submit()">Submit
                                    &nbsp;&nbsp;&nbsp;<i class="fa-solid fa-thumbs-up"></i></button>
                            </div>
                            <div *ngIf="errataFlag" class="col-md-3">
                                <button nbButton status="primary" class="form-control" (click)="update(app_id)">Update
                                    &nbsp;&nbsp;&nbsp;<i class="fa-solid fa-file-pen"></i></button>
                            </div>
                            <!-- <div class="col-md-3">
                                <button [disabled]="showpayment" (click)="proceedforpayment()" nbButton status="primary"
                                    class="form-control">Pay</button><br><br>
                            </div> -->
                        </div><br>

                        <!-- <div class="row">
                        <div class="col-md-3">
                            <button nbButton status="info" class="form-control">Add
                                More<i class="ai-plus"></i></button>
                        </div>
                        <div class="col-md-4">
                            <button [disabled]="showpayment" (click)="proceedforpayment()" nbButton status="primary" class="form-control">Proceed
                                to Payment</button><br><br>
                        </div>
                    </div> -->

                    </div>
                </form>
            </nb-card-body>



        </nb-card>

    </div>
</div>