import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
}) 
export class ContactUsComponent implements OnInit {
  helpEmailId = environment.testUser.helpEmailId;
  helpMobNo = environment.testUser.helpMobNo;

  constructor(protected ref: NbDialogRef<ContactUsComponent>, ) { }

  ngOnInit(): void {
  
  }
  
  close() { 
    this.ref.close();
  }


}