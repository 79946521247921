import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbCardModule, NbDatepickerModule, NbFormFieldModule, NbInputModule, NbListModule, NbPopoverModule, NbTagModule } from '@nebular/theme';
import { SharedService } from '../../../@core/backend/common/services/shared.service';
import { thirdPartyApplicationComponent } from '../third-party-apply/third-party-application.component';
// import { ViewApplicationsComponent } from '../view-applications/view-applications.component';
import { AgentDashboardComponent } from './agent-dashboard.component';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
    imports: [
        NbCardModule,
        NbDatepickerModule,
        FormsModule,
        ReactiveFormsModule,
        NbListModule,
        CommonModule,
        NgxPaginationModule,
        NbTagModule,
        NbInputModule,
        NbFormFieldModule,
        NbPopoverModule
      ],
      providers: [SharedService],
declarations: [
  // ViewApplicationsComponent,
  thirdPartyApplicationComponent,
    AgentDashboardComponent
  ],
})

export class AgentDashboardModule { }
