import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

@Injectable()
export class NetworkService {
  getMachinId(user_id) {
    try {
      var machineId = localStorage.getItem('MachineId'); var machineKey;
      if (!machineId) {
        let uuid = uuidv4();
        
        machineKey = uuid + "-" + user_id;
        localStorage.setItem('MachineId', machineKey);
      } else {
        machineKey = machineId;
      }
      return machineKey;
    } catch (error) {
      
    }
  }

}
