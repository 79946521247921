import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpService } from './http.service';

@Injectable()
export class RegisterUserApi {
  private readonly apiController: string = 'registeruser';

  constructor(private api: HttpService,
    private http:HttpClient) { }

    getRegisterUser(email,name,page){
      try{
        return this.http.get(`${environment.apiUrl}/getRegisterUser?email=`+email+'&name='+name+'&page='+page)
      }catch(error){
        
      }
    }

    sendOtp(user_id){
      try{
        return this.http.get(`${environment.apiUrl}/sendOtp?user_id=`+user_id)
      }catch(error){
        
      }
    }

    updateOtp(user_id){
      try{
        return this.http.post(`${environment.apiUrl}/updateOtp`, { 'user_id': user_id })
      }catch(error){
        
      }
    }
   

   

}