<nb-card>
    <nb-card-header>

        <div class="form-label2 color-theme">
  Help <i class="fa fa-window-close  fa-pull-right " (click)="close()" aria-hidden="true" ></i>
            
        </div>
    </nb-card-header>

    <nb-card-body>
        For any query, kindly mail us on <h6 style="color: red;"> {{helpEmailId}}</h6>
        
        contact us on whatsapp <span style="font-weight: bold; color: red;">{{helpMobNo}}</span>
    </nb-card-body>
</nb-card> 