import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApplicationApi } from './../../../@core/backend/common/api/application.api';
import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { FormBuilder, FormGroup,Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Router , ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http'; 


@Component({
selector: 'nb-dialog',
template: `
<nb-card [style.width.px]="700" [style.height.px]="400" status="success" accent="success" size="xsmall">
  <nb-card-header>
    <div class="row">
      <div class="col-md-3">
      </div>
      <div class="col-md-6">
        <h3 style="color:#ffffff">PAYMENT DETAILS</h3>
      </div>
      <div class="col-md-3">
      </div>
    </div>
  </nb-card-header>
  <nb-card-body>
    <div class="row" style="margin-bottom:7px">
      <div class="col-md-3">Name</div>
      <div class="col-md-9"> 
        <input type="text" nbInput fullWidth placeholder="Name" ngModel="{{app_name}}" [readonly]="true">
      </div>
    </div>
    <div class="row" style="margin-bottom:7px">
      <div class="col-md-3">Email</div>
      <div class="col-md-9"> 
        <input type="text" nbInput fullWidth placeholder="Email" ngModel="{{app_email}}" [readonly]="true">
      </div>
    </div>
    <div class="row" style="margin-bottom:7px">
      <div class="col-md-3">Amount</div>
      <div class="col-md-9"> 
        <input type="text" nbInput fullWidth placeholder="Amount" ngModel="{{app_Payment}}" [readonly]="true">
      </div>
    </div>
    <div class="row" style="margin-bottom:7px">
    <div class="col-md-3">Mobile No.</div>
    <div class="col-md-9"> 
      <input type="text" nbInput fullWidth placeholder="Mobile no." ngModel="{{app_mobile}}" [readonly]="true">
    </div>
  </div>
 
  </nb-card-body>
  <nb-card-footer>
    <div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
      <button nbButton hero status="primary" (click)="dismiss()">Close</button><button nbButton hero status="primary" (click)="firstpayment()">Proceed For Payment</button>
    </div>
    <div class="col-md-3"></div>
    </div>
  </nb-card-footer>
  
</nb-card>
<div>
<form id="nonseamless" method="post" name="redirect" action="{{secureUrl}}"> <input type="hidden" id="encRequest" name="encRequest" value="{{encRequest}}"><input type="hidden" name="access_code" id="access_code" value="{{accessCode}}"></form>
</div>
`,
})
export class Firstpaymentdialog {
@Input() app_email: string;
@Input() app_name: string;
@Input() app_id;

@Input() app_Payment;
@Input() app_mobile;

user_data;
applicationId;
courseID;
accessCode;
secureUrl;
encRequest;
loading = false;
// amount = '1273.00';

constructor(protected ref: NbDialogRef<Firstpaymentdialog>,
  protected api : ApplicationApi,
  private authService: NbAuthService,
  private router: Router,
  private route: ActivatedRoute,
  private httpClient : HttpClient,) {
  }

  dismiss() {
  this.ref.close();
  }


  ngOnInit() {
    
    
    // this.api.getProfileValue('Personal')
    //   .subscribe(
    //     (data: any) => {  
    //       this.user_data =  data['data']['user_data'];
    //       //this.amount = 'INR 3605';
    //       this.amount = 'INR 3863';
    //       err => 
    //   });
  }

  async firstpayment(){
    
    var firstpayment = await this.api.paymentrequest(this.app_id, this.app_name, this.app_email, this.app_Payment);
    firstpayment.subscribe(
        data => {
          
          
          this.accessCode = data['data']['accessCode'];
          //
          this.secureUrl = data['data']['secureUrl'];
          //
          this.encRequest = data['data']['encRequest'];
          //
          setTimeout(function(){ 
           //
           this.loading = false;
            var myForm = <HTMLFormElement>document.getElementById('nonseamless');
            //
            myForm.submit();
          }, 1000);
        },
        error => {
            
        }
    ); 
  }
}
