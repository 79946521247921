import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import {NbTokenLocalStorage,} from '@nebular/auth';
@Injectable()

export class TokenServices {
    constructor(
        private localstorage:NbTokenLocalStorage,

    ){
          
    }
    getToken(){
        var token = this.localstorage.get();
        return this.getDecodedAccessToken(token['token']['access_token'])
    }
    getDecodedAccessToken(token: string): any {
        try {
          return jwt_decode(token);
        } catch(Error) {
          return null;
        }
      }
}